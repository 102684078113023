import "../styles/Projects.scss";
import projects from "../datas/Projects";

function Projects() {
  return (
    <section id='projects' className='section'>
      <div className="content">
      <h2 className='section_title'><span className='section_title_border'>PROJETS</span></h2>
        <ul className='projects'>
          {projects.map((project, index) => (
            <li key={index} className='projects_item' style={{ backgroundImage: `url(${project.img})` }}>
                  <a className="projects_item_link" href={project.link} target='_blank' rel="noreferrer">
                    <div className="projects_item_link_content">
                      <h3 className='projects_item_link_title'>{project.title}</h3>
                      <p className='projects_item_link_text'>{project.text}</p>
                    </div>
                  </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Projects;
