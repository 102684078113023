import '../styles/App.scss'
import Header from './Header'
import Intro from './Intro'
import Cv from './Cv'
import Projects from './Projects'
import Contact from './Contact'
import Footer from './Footer'

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Intro />
        <Cv />
        <Projects />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
