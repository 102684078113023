import React from 'react';
import '../styles/Header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.mobileMenu = React.createRef();
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu() {
    this.mobileMenu.current.classList.toggle('open');
  }

  render() {
    return (
      <header className="header">
        <div className='header_content'>
          <div><h1 className='title'>YANIS</h1></div>
          <nav>
            <ul className="main-menu">
              <li className="main-menu_item"><a className="main-menu_item_link" href="#cv">COMPÉTENCES</a></li>
              <li className="main-menu_item"><a className="main-menu_item_link" href="#projects">PROJETS</a></li>
              <li className="main-menu_item"><a className="main-menu_item_link" href="#contact" >CONTACT</a></li>
            </ul>
          </nav>
          <div id="hamburger-icon" ref={this.mobileMenu} onClick={this.toggleMobileMenu}>
            <div className='bar1'></div>
            <div className='bar2'></div>
            <div className='bar3'></div>
            <ul className="mobile-menu">
              <li className="mobile-menu_item"><a className="main-menu_item_link" href="#cv">COMPÉTENCES</a></li>
              <li className="mobile-menu_item"><a className="main-menu_item_link" href="#projects">PROJETS</a></li>
              <li className="mobile-menu_item"><a className="main-menu_item_link" href="#contact" >CONTACT</a></li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;