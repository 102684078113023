import '../styles/Footer.scss'

function Footer() {
  const date = new Date().getFullYear()

  return (
    <footer className='footer'>
      <div className='footer_content'>
        <div className='footer_content_top'>
          <div className='footer_content_top_contact'>
            <h4 className='footer_content_top_contact_h4'>Yanis L</h4>
            <div className='footer_content_top_contact_media'>
              <a href='/' className='footer_content_top_contact_media_item'><span><img src={require('../assets/images/footer/linkedin.png')} alt='linkedin'/></span></a>
              <a href='/' className='footer_content_top_contact_media_item'><span><img src={require('../assets/images/footer/github.png')} alt='github'/></span></a>
            </div>
          </div>
          <ul className='footer_content_top_menu'>
            <li className='footer_content_top_menu_item'><a href='#intro'>Home</a></li>
            <li className='footer_content_top_menu_item'><a href='#cv'>Compétences</a></li>
            <li className='footer_content_top_menu_item'><a href='#projects'>Projets</a></li>
            <li className='footer_content_top_menu_item'><a href='#contact'>Contact</a></li>
          </ul>
        </div>
        <div className='footer_content_bottom'>Yanis L. {date}. Tous Droits Réservés.</div>
      </div>
    </footer>
  );
}

export default Footer;