const skills = [
    {
        title: 'JavaScript',
        img: require('../assets/images/skills/js.png')
    },
    {
        title: 'HTML',
        img: require('../assets/images/skills/html.png')
    },
    {
        title: 'CSS',
        img: require('../assets/images/skills/css.png')
    },
    {
        title: 'SASS',
        img: require('../assets/images/skills/sass.png')
    },
    {
        title: 'SQL',
        img: require('../assets/images/skills/sql.png')
    },
    {
        title: 'Vue.js',
        img: require('../assets/images/skills/vue.png')
    },
    {
        title: 'Node',
        img: require('../assets/images/skills/node.png')
    },
    {
        title: 'Bootstrap',
        img: require('../assets/images/skills/bootstrap.png')
    },
    {
        title: 'Github',
        img: require('../assets/images/skills/github.png')
    },
    {
        title: 'WordPress',
        img: require('../assets/images/skills/wordpress.png')
    }
]

export default skills;