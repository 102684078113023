import "../styles/Cv.scss";
import skills from "../datas/Skills";

function Cv() {
  return (
    <section id='cv' className='section'>
      <div className="content">
        <h2 className="section_title"><span className='section_title_border'>COMPÉTENCES</span></h2>
        <ul className='skills'>
          {skills.map((skill, index) => (
            <li key={index} className='skills_item'>
              <img
                className='skills_item_img'
                src={skill.img}
                alt={skill.title}
                width={64}
                height={64}
              />
              <h3 className='skills_item_title'>{skill.title}</h3>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Cv;
