const projects = [
    {
        title: 'Groupomania',
        text: 'Réseau social d\'entreprise',
        link: 'https://github.com/yanisld/groupomania',
        img: require('../assets/images/projects/groupomania.jpg')
    },
    {
        title: 'Piiquante',
        text: 'Site de sauce de piquante',
        link: 'https://github.com/yanisld/piiquante',
        img: require('../assets/images/projects/piiquante.jpg')
    },
    {
        title: 'Orinoco',
        text: 'Site e-commerce',
        link: 'https://github.com/yanisld/orinoco',
        img: require('../assets/images/projects/orinoco.jpg')
    },
    {
        title: 'OhMyFood!',
        text: 'Interface de réservation de plat en ligne',
        link: 'https://github.com/yanisld/ohmyfood',
        img: require('../assets/images/projects/ohmyfood.jpg')
    },
    {
        title: 'Reservia',
        text: 'Interface d\'un site de voyage',
        link: 'https://github.com/yanisld/reservia',
        img: require('../assets/images/projects/reservia.jpg')
    },
    {
        title: 'Terre de Caprices',
        text: 'Site d\'un poseur de béton ciré',
        link: 'http://www.terre-de-caprices.fr/',
        img: require('../assets/images/projects/terredecaprices.jpg')
    }
]

export default projects;