import "../styles/Contact.scss";
//import { useState } from "react";

function Contact() {
  /*const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  const submitEmail = async (e) => {
    e.preventDefault();
    try {
      console.log(mailerState);
      const res = await fetch("http://dev.yanislaidouni.fr/send", {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(mailerState),
      });
      console.log(res);
      const resData = await res.json()
      if (resData.status === "success") {
        alert("Message Sent");
        setMailerState({ name: "", email: "", subject: "", message: "" });
      } else if (resData.status === "fail") {
        alert("Message failed to send");
      }
    }
    catch(err) { console.error(err) }
  };*/

  return (/*
    <section id='contact' className='section'>
      <div className="content">
        <h2 className="section_title"><span className='section_title_border'>CONTACT</span></h2>
        <div className="contact">
          <form className="contact_form" onSubmit={submitEmail}>
          <input className="contact_form_input" type="text" onChange={handleStateChange} name="name" value={mailerState.name} placeholder="Nom" required/>
          <input className="contact_form_input" onChange={handleStateChange} type="email" name="email" value={mailerState.email} placeholder="E-mail" required/>
          <input className="contact_form_input" onChange={handleStateChange} type="text" name="subject" value={mailerState.subject} placeholder="Sujet" required/>
          <textarea className="contact_form_input" onChange={handleStateChange} name="message" value={mailerState.message} placeholder="Message" required></textarea>
          <input className="contact_form_submit" type="submit" value="Envoyer" />
          </form>
        </div>
      </div>
    </section> */
    <section id='contact' className='section'>
      <div className="content">
        <h2 className="section_title"><span className='section_title_border'>CONTACT</span></h2>
        <div className="contact">
          <div className="contact_mail">yanis.laidouni[at]outlook.fr</div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
