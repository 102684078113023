import '../styles/Intro.scss'

function Intro() {
  return (
    <section id='intro' className='intro'>
      <div className='content'>
        <div className='main-title'><span className='main-title_border'>DÉVELOPPEUR WEB</span></div>
        <p className='presentation'>
          Développeur web, j'ambitionne d'être complet avec du développement full-stack.
          Travaillant aujourd'hui dans un environnement JavaScript, je suis ouvert à toutes les technologies et outils.
        </p>
        <a className='intro_btn' href='#contact'>Discutons !</a>
      </div>
    </section>
  );
}

export default Intro;